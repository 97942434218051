<template>
  <b-col cols="12">
    <h-card class="card-loading">
      <div class="loading-screen">
        <div class="loading-screen-content">
          <div class="loader mb-5"></div>
          <p>{{ currentLoadingMessage }}</p>
        </div>
      </div>
    </h-card>
  </b-col>
</template>

<script>
export default {
  props: {
    currentLoadingMessage: String,
  },
}
</script>

<style lang="scss" scoped>
.card-loading {
  height: 600px;
}
.loading-screen {
  display: flex;
  position: absolute;
  z-index: 2;
  height: 600px;
  width: 100% !important;
  justify-content: center;
  align-items: center;
}
.loading-screen-content {
  position: relative;
  z-index: 3;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 90px;
  height: 16px;
  border-bottom: 2px solid #323f5a;
  box-shadow: 0 3px 0 #fff;
  position: relative;
  clip-path: inset(-40px 0 -5px);
}
.loading-screen p {
  font-weight: 600;
  font-size: 17px;
  color: $h-main-text;
}
.loader:before {
  content: '';
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  margin-bottom: 3px;
  --g: no-repeat linear-gradient(#323f5a 0 0);
  background: var(--g), var(--g), var(--g), var(--g);
  background-size: 16px 14px;
  animation: l7-1 2s infinite linear, l7-2 2s infinite linear;
}
@keyframes l7-1 {
  0%,
  100% {
    background-position: 0 -50px, 100% -50px;
  }
  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }
  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }
  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }
  70%,
  98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px),
      100% calc(100% - 16px);
  }
}
@keyframes l7-2 {
  0%,
  70% {
    transform: translate(0);
  }
  100% {
    transform: translate(200%);
  }
}
</style>
