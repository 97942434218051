<template>
  <b-row>
    <b-col cols="12">
      <b-row
        class="mt-4 loading-container-main"
        v-if="
          loadingMessage && (!messagesToVoice[0] || !messagesToVoice[0].text)
        "
      >
        <NewMessageVoiceLoading
          :currentLoadingMessage="currentLoadingMessage"
        />
      </b-row>

      <b-row
        class="mt-4"
        v-for="(message, index) in messagesToVoice"
        :key="index"
      >
        <NewMessageVoiceItem :message="message" :index="index" />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-slider-component/theme/antd.css'
import NewMessageVoiceItem from '../NewMessageVoiceItem.vue'
import NewMessageVoiceLoading from '../NewMessageVoiceLoading.vue'

export default {
  components: {
    NewMessageVoiceItem,
    NewMessageVoiceLoading,
  },
  data() {
    return {
      loadingMessage: false,
      currentIndex: 0,
      currentLoadingMessage: '',
      interval: null,
      loadingSteps: [
        this.$t('messages.messageTextProcessing1'),
        this.$t('messages.messageTextProcessing2'),
        this.$t('messages.messageTextProcessing3'),
        this.$t('messages.messageTextProcessing4'),
      ],
    }
  },
  mounted() {
    this.$webSocketsConnect()
  },
  activated() {
    //get compositing options
    this.$store.dispatch('message/_getCompositingOptions')

    this.loadingMessage = false
    this.$webSocketsConnect()
    const isMessagesGenerated = this.currentMessage.messagesContent.length
      ? this.currentMessage.messagesContent[0]?.text
      : ''

    if (isMessagesGenerated && isMessagesGenerated.length > 50) return
    if (this.messageRedactionIA) {
      this.generateMessage()
    } else this.manualMessage()
  },
  computed: {
    ...mapState({
      messageGenerationState: (state) =>
        state.message.newMessage.messageGenerationState,
      currentMessage: (state) => state.message.newMessage,
      messageRedactionIA: (state) =>
        !state.message.newMessage.messageRedactionManual,
      newMessageComp: (state) => state.message.newMessage,
      messageVoiceState: (state) => state.message.newMessage.messageVoiceState,
      messagesToVoice: (state) => state.message.newMessage.messagesContent,
    }),
    messagesContent: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.messagesContent
      },
      // setter
      set: function(message) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messagesContent',
          value: message,
        })
      },
    },
  },
  methods: {
    displayLoadingAnimation() {
      if (this.currentIndex < this.loadingSteps.length - 1) {
        this.currentLoadingMessage = this.loadingSteps[this.currentIndex]
        const delay = Math.random() * (8000 - 5000) + 5000
        this.interval = setTimeout(() => {
          this.currentIndex++
          this.displayLoadingAnimation()
        }, delay)
      } else {
        this.currentLoadingMessage = this.loadingSteps[this.currentIndex]
      }
    },
    generateMessage() {
      if (this.messageGenerationState === 'running') return

      this.messagesContent = Array.from({ length: 5 }, () => ({
        text: '',
        selected: false,
      }))
      //Loading animation
      this.loadingMessage = true
      this.currentIndex = 0
      this.displayLoadingAnimation()

      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageGenerationState',
        value: 'running',
      })

      const promptData = JSON.stringify({
        Content: {
          IdTransaction: this.$store.state.message.newMessage.idTransaction,
          Parameters: {
            product: {
              name: this.currentMessage.productDescription,
              features: this.currentMessage.keywords.split(','),
              price: this.currentMessage.price,
              target: this.currentMessage.target.value,
            },
            generation: {
              model: 'TestPat',
              count: '5',
            },
          },
          Provider: 'MessageWritingHelper',
        },
        Header: {
          Type: 'ProviderRequest',
          idbrand: this.$store.state.user.user.visibleBrands[0].id,
        },
      })
      this.$webSocketsSend(promptData)
    },
    manualMessage() {
      if (this.messagesContent.length === 0) {
        this.messagesContent = Array.from({ length: 5 }, () => ({
          text: '',
          selected: true,
        }))
      }
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageGenerationState',
        value: 'done',
      })
    },
  },
  beforeDestroy() {
    clearTimeout(this.interval)
  },
}
</script>

<style lang="scss" scoped>
.loading-container-main {
  height: 600px;
}
h6 {
  color: #848c9c;
}
p {
  color: #70798b;
}

.margin-comp {
  margin-top: 32px;
}

button.disabled {
  pointer-events: none;
}
</style>
