<template>
  <div>
    <div class="bg-white shadow-sm breadcrumbs-container py-2 px-3 d-flex">
      <template v-if="$store.state.message.newMessage.messageType !== ''">
        <span
          v-for="(step, index) in messageSteps"
          :key="index"
          class="text-truncate"
          :class="{ active: currentRouteName - 1 == index }"
        >
          <span class="name">
            <b-icon
              v-if="index > 0"
              icon="chevron-double-right"
              class="ico mx-2"
              scale="0.8"
            ></b-icon>
            {{ step }}
          </span>
        </span>
      </template>
      <template v-else>
        <span class="active">
          <span class="name">
            {{ $t('messages.new') }}
          </span>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      manualSteps: [
        this.$t('messages.new'),
        this.$t('messages.contensAndVoiceGen'),
        this.$t('messages.messagesSchedule'),
      ],
      iaSteps: [
        this.$t('messages.new'),
        this.$t('messages.writingAssist'),
        this.$t('messages.contensAndVoiceGen'),
        this.$t('messages.messagesSchedule'),
      ],
      recordSteps: [
        this.$t('messages.new'),
        this.$t('messages.record'),
        this.$t('messages.messageSchedule'),
      ],
    }
  },
  computed: {
    currentRouteName() {
      const routeStep = this.$route.name.split('/')[1]
        ? this.$route.name.split('/')[1]
        : this.$route.name
      return parseInt(routeStep)
    },
    messageSteps() {
      const {
        messageType,
        messageRedactionManual,
      } = this.$store.state.message.newMessage

      return messageType === 'ia'
        ? messageRedactionManual
          ? this.manualSteps
          : this.iaSteps
        : this.recordSteps
    },
  },
  props: {
    breadcrumbs: Array,
    detailPage: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    routerLink(routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  border-left: solid 3px $primary;
  border-radius: 4px;
  font-weight: 300;
  height: 40px;
  color: #848c9c;
  span.active .name {
    font-weight: 600;
    color: $primary;
    pointer-events: none;
  }
}
</style>
