<template>
  <div :class="{ container: isEdit }">
    <b-row>
      <b-col md="6">
        <h-card class="h-100" :class="{ 'mt-3': isEdit, 'mt-4': !isEdit }">
          <h4 class="my-0 text-center">
            {{
              selectedmessagesContent.length > 1
                ? $t('messages.yourMessagesTitle')
                : $t('messages.yourMessageTitle')
            }}
          </h4>
          <b-row class="mt-4">
            <b-col cols="12">
              <b-row class="align-items-start mt-4">
                <b-col xl="4" class="mb-2 mb-xl-0 mt-2">
                  {{
                    selectedmessagesContent.length > 1
                      ? $t('messages.messagesName')
                      : $t('messages.messageName')
                  }}</b-col
                >
                <b-col xl="8">
                  <b-form-group type="text" class="mb-0">
                    <b-form-input
                      id="message-name"
                      :disabled="isEdit"
                      v-model="messageNameLocal"
                      type="text"
                      trim
                      @input="updateName()"
                      :state="hasNameError"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.messageNameLocal.minLength"
                    >
                      Le nom du message doit comporter au moins 4 caractères
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.messageNameLocal.maxLength"
                    >
                      Le nom du message doit comporter au maximum 60 caractères
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.messageNameLocal.required"
                    >
                      Le nom du message est obligatoire
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="!isEdit" class="align-items-start mt-4">
                <b-col xl="4" class="mb-2 mb-xl-0 mt-2">{{
                  $t('messages.messageTag')
                }}</b-col>
                <b-col xl="8">
                  <h-multi-select
                    v-if="tagsOptions"
                    multiple
                    v-model="selectedTags"
                    placeholder="Selectionner tag(s)"
                    left-icon="tags"
                    :options="tagsOptions"
                    :selectedTags="selectedTags"
                  ></h-multi-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
      <b-col md="6">
        <h-card class="h-100" :class="{ 'mt-3': isEdit, 'mt-4': !isEdit }">
          <h4 class="my-0 text-center">
            {{
              selectedmessagesContent.length > 1
                ? $t('messages.schedulingMessagesTitle')
                : $t('messages.schedulingMessageTitle')
            }}
          </h4>
          <b-row class="mt-4">
            <b-col cols="12">
              <!-- //Start Date -->
              <b-row class="align-items-center mt-4">
                <b-col xl="5" class="mb-2 mb-xl-0">
                  <label for="startDate" class="mb-0">
                    {{ $t('messages.startDateBroadcast') }}</label
                  >
                </b-col>
                <b-col xl="7">
                  <b-button-group class="d-flex">
                    <b-button
                      @click="startNowBtn"
                      :class="{ active: startDateTrigger === 'now' }"
                      class="default-select-date btn-group-left"
                      variant="outline-info"
                    >
                      {{ $t('messages.today') }}</b-button
                    >
                    <v-date-picker
                      is-required
                      :attributes="attrs"
                      :min-date="tomorrowDate"
                      :popover="{
                        visibility: 'click',
                        placement: $screens({
                          default: 'bottom-end',
                          sm: 'bottom-end',
                          md: 'bottom-end',
                          lg: 'bottom-start',
                          xl: 'bottom-start',
                        }),
                      }"
                      :highlight="{ backgroundColor: 'red' }"
                      v-model="startDateLocal"
                      :value="startDateLocal"
                      @input="updateStartDate"
                    >
                      <template v-slot="{ inputEvents }">
                        <b-button
                          v-on="inputEvents"
                          @click="startLaterBtn"
                          :class="{ active: startDateTrigger === 'later' }"
                          class="btn-group-right"
                          variant="outline-info"
                        >
                          {{ startDateLabel() }}
                        </b-button>
                      </template>
                    </v-date-picker>
                  </b-button-group>
                </b-col>
              </b-row>
              <!-- //End Date -->
              <b-row class="mt-4 align-items-center">
                <b-col xl="5" class="mb-2 mb-xl-0">
                  <label for="endDate" class="mb-0">
                    {{ $t('messages.endBroadcast') }}
                  </label>
                </b-col>
                <b-col xl="7">
                  <b-button-group class="d-flex">
                    <b-button
                      @click="noEndBtn"
                      :class="{ active: endDateTrigger === 'noEnd' }"
                      class="default-select-date btn-group-left"
                      variant="outline-info"
                    >
                      {{ $t('messages.noEnd') }}
                    </b-button>
                    <v-date-picker
                      is-required
                      v-model="endDateLocal"
                      :value="endDateLocal"
                      @input="updateEndDate"
                      :min-date="startDateToJs"
                      :attributes="attrs"
                      :popover="{
                        visibility: 'click',
                        placement: $screens({
                          default: 'bottom-end',
                          sm: 'bottom-end',
                          md: 'bottom-end',
                          lg: 'bottom-start',
                          xl: 'bottom-start',
                        }),
                      }"
                    >
                      <template v-slot="{ inputEvents }">
                        <b-button
                          v-on="inputEvents"
                          @click="endDateBtn"
                          :class="{ active: endDateTrigger === 'date' }"
                          class="btn-group-right"
                          :variant="
                            !$v.endDateLocal.minValue && $v.endDateLocal.$model
                              ? 'outline-danger'
                              : 'outline-info'
                          "
                        >
                          {{ endDateLabel() }}
                        </b-button>
                      </template>
                    </v-date-picker>
                  </b-button-group>
                  <div
                    class="d-block invalid-feedback"
                    v-if="!$v.endDateLocal.minValue && $v.endDateLocal.$dirty"
                  >
                    {{ $t('messages.endDateError') }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col xl="5" class="mb-2 mb-xl-0 mt-2">
                  <div>{{ $t('messages.broadcastIntensity') }}</div></b-col
                >
                <b-col xl="7">
                  <h-select
                    class="flex-grow-1"
                    v-model="intensityLocal"
                    :placeholder="$t('messages.chooseBroadcastIntensity')"
                    left-icon="megaphone"
                    :options="intensityOptions"
                    notClearable
                  ></h-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <h-card>
          <h5 class="h5 mt-2" v-b-toggle.schedule-advanced>
            <b-icon
              :icon="advancedParams ? 'dash-circle-fill' : 'plus-circle-fill'"
              class="mr-2"
            ></b-icon>
            {{ $t('messages.advancedParameters') }}
          </h5>
          <b-collapse v-model="advancedParams" id="schedule-advanced">
            <b-row>
              <b-col md="6">
                <!-- //recurrence -->
                <b-row>
                  <b-col cols="12">
                    <h5>{{ $t('planification.weeklyReccurence') }}</h5>
                  </b-col>
                  <b-col cols="12" class=" mt-xl-0 d-flex align-items-center">
                    <b-badge
                      class="badge-day cursor-pointer py-2 mr-1 px-4"
                      v-for="(day, index) in days"
                      :key="index"
                      pill
                      :variant="day.selected ? 'info text-white' : 'light'"
                      @click="dayClick(day, index)"
                      >{{ day.label }}
                    </b-badge>
                  </b-col>
                </b-row>
                <h5 class="mb-2">{{ $t('messages.timeSlots') }}</h5>
                <TimeSlots
                  :scheduleId="null"
                  @changeTimeslots="changeTimeslots"
                  :currentTimeSlots="timeSlotsComp"
                />
              </b-col>
              <b-col md="6">
                <h5 class="mb-2">{{ $t('messages.exclusionPeriods') }}</h5>
                <MusiqueModalOptExclud
                  :currentExcludes="exclusionLocal"
                  :scheduleId="-1"
                  @changeExclusion="changeExclusion"
                />
                <!-- <h5 class="mb-2">Options liées à la météo :</h5>
                <MusiqueModalOptWeather
                  @weatherParamsChange="changeWeatherParams"
                  :current-weather="weather"
                /> -->
              </b-col>
            </b-row>
          </b-collapse>
        </h-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="isEdit">
      <b-col class="d-flex justify-content-between">
        <h-button @click="cancel" outline red class="my-3">
          <b-icon icon="x" class="mr-2"></b-icon>
          {{ $t('actions.cancel') }}
        </h-button>
        <!-- //Next buttons for IA Creation Path -->
        <h-button
          @click="editMessageSchedule"
          green
          class="my-3 "
          :disabled="$v.$anyError"
        >
          {{ $t('actions.save') }}
          <b-icon icon="arrow-right" class="ml-2"></b-icon>
        </h-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import dateFormat from '@/components/dateFormat.js'
import MusiqueModalOptExclud from '@/components/musique/MusiqueModalOptExclud'
import TimeSlots from '@/components/message/TimeSlots'
// import MusiqueModalOptWeather from '@/components/musique/MusiqueModalOptWeather'
import messageService from '@/services/messageService.js'

export default {
  components: {
    MusiqueModalOptExclud,
    TimeSlots,
    // MusiqueModalOptWeather,
  },
  data() {
    return {
      messageNameLocal: '',
      startDateLocal: moment(),
      endDateLocal: moment('01/01/2099'),
      intensityLocal: { label: 'Normal', value: 0 },
      exclusionLocal: [],
      advancedParams: false,
      selectedTags: [],

      base64: null,
      alreadyUsed: false,
      date: new Date(),
      loaded: true,
      noEndDate: moment('01/01/2099'),
      startDateTrigger: 'now',
      endDateTrigger: 'noEnd',
      uploading: false,
      intensityOptions: [
        { label: 'Normal', value: 0 },
        { label: 'Souvent', value: 1 },
        { label: 'Très souvent', value: 2 },
        { label: 'Exclusif', value: 3 },
      ],
      attrs: [
        {
          dot: {
            color: 'blue',
          },
          dates: new Date(),
        },
      ],
      days: [
        { selected: true, label: this.$t('planification.monday') },
        { selected: true, label: this.$t('planification.tuesday') },
        { selected: true, label: this.$t('planification.wednesday') },
        { selected: true, label: this.$t('planification.thursday') },
        { selected: true, label: this.$t('planification.friday') },
        { selected: true, label: this.$t('planification.saturday') },
        { selected: true, label: this.$t('planification.sunday') },
      ],
      excludPeriod: false,
      timeSlots: false,
      weatherParams: false,
    }
  },
  validations() {
    return {
      messageNameLocal: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(60),
      },
      startDateLocal: {
        required,
      },
      endDateLocal: {
        minValue: (value) => moment(value) >= moment(this.startDateLocal),
      },
    }
  },
  computed: {
    ...mapState({
      tagsOptions: (state) => state.message.tags,
      fileuuidFromWs: (state) => state.message.newMessage.fileuuid,
      messagesContent: (state) => state.message.newMessage.messagesContent,
      dtException: (state) => state.message.newMessage.dtException,
    }),
    hasNameError() {
      if (this.$v.messageNameLocal.$anyError || this.alreadyUsed) return false
      else return null
    },
    selectedmessagesContent() {
      const selectedMessages = this.messagesContent.filter((e) => e.selected)
      return selectedMessages
    },
    isEdit() {
      return this.$route.params.id > 0
    },
    audioSRC() {
      return `https://fg0.radioshop.net/file?uuid=${this.fileuuidFromWs}`
    },
    todaysDate() {
      // Utiliser un cache si nécessaire
      return moment()
    },
    startDateToJs() {
      return new Date(this.startDateLocal)
    },
    tomorrowDate() {
      // Simplification du calcul de demain
      return moment()
        .add(1, 'days')
        .toDate()
    },
    ...mapGetters({
      messageName: 'message/newMessage/messageName',
      startDate: 'message/newMessage/startDate',
      endDate: 'message/newMessage/endDate',
      dayOfWeek: 'message/newMessage/dayOfWeek',
      weather: 'message/newMessage/weather',
    }),
    messageName: {
      get() {
        return this.$store.state.message.newMessage.messageName
      },
      set(name) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messageName',
          value: name,
        })
      },
    },
    startDate: {
      get() {
        return this.$store.state.message.newMessage.startDate
      },
      set(date) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'startDate',
          value: date,
        })
      },
    },
    endDate: {
      get() {
        return this.$store.state.message.newMessage.endDate
      },
      set(date) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'endDate',
          value: date,
        })
      },
    },
    dayOfWeek: {
      get() {
        return this.$store.state.message.newMessage.dayOfWeek
      },
      set(number) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'dayOfWeek',
          value: number,
        })
      },
    },
    weather: {
      get() {
        return this.$store.state.message.newMessage.weather
      },
      set(val) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'weather',
          value: val,
        })
      },
    },
    timeSlotsComp() {
      const startTimes = this.$store.state.message.newMessage.startTimes
      const endTimes = this.$store.state.message.newMessage.endTimes
      let formattedArray = []
      if (startTimes && endTimes) {
        formattedArray = startTimes.map((time, index) => {
          return {
            start: time,
            end: endTimes[index],
          }
        })
      }
      return formattedArray
    },
  },
  watch: {
    exclusionLocal(val) {
      if (val) {
        this.$store.dispatch('message/_setNewdtExclusion', val)
      }
    },
    selectedTags(val) {
      if (val) {
        this.$store.dispatch('message/_setSelectedTags', val)
      }
    },
    intensityLocal(val) {
      if (val) {
        this.$store.dispatch('message/_setIntensity', val.value)
      }
    },
    startDateLocal() {
      this.updateStartDate()
    },
    endDateLocal() {
      this.updateEndDate()
    },
  },
  async mounted() {
    if (this.isEdit) {
      const idSchedule = this.$route.params.id
      const res = await messageService.getFullSchedule(idSchedule)
      this.$store.dispatch('message/_matchEditSchedule', res.data)
    } else {
      this.startNowBtn()
    }

    //map data on refreshing page or Edit mode
    this.messageNameLocal = this.messageName
    this.startDateLocal = this.startDate
    this.endDateLocal = this.endDate
    this.exclusionLocal = this.dtException

    //Day of week
    for (let i = 0; i < 7; i++) {
      let isBitSet = (this.dayOfWeek & (1 << i)) !== 0
      this.days[i].selected = isBitSet
    }

    //Select start & end date button active
    if (
      this.startDate &&
      this.formatDateFr(this.startDate) !== this.formatDateFr(this.todaysDate)
    )
      this.startDateTrigger = 'later'
    if (
      this.endDate &&
      this.formatDateFr(this.endDate) != '01/01/2060' &&
      this.formatDateFr(this.endDate) != '01/01/2099'
    )
      this.endDateTrigger = 'date'

    this.endDateLocal = this.endDate
    this.intensityLocal = this.intensityOptions[
      this.$store.state.message.newMessage.intensity
    ]

    await this.$store.dispatch('message/_getTags')
    const idsTagList = this.$store.state.message.newMessage.idTagList
    this.selectedTags = [...idsTagList]
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'messages' })
    },
    async editMessageSchedule() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      //Edition du schedule de message
      const newMessage = this.$store.state.message.newMessage
      const currentSiteId = this.$store.state.user.selectedSite.id

      const schedule = {
        active: true,
        content: null,
        size: 0,
        scheduleId: parseInt(this.$route.params.id),
        messageName: newMessage.messageName,
        startDate: dateFormat.encodeServerDate(newMessage.startDate),
        endDate: dateFormat.encodeServerDate(newMessage.endDate),
        frequency: newMessage.frequency,
        IdTagList: newMessage.idTagList,
        idBrand: this.$store.state.user.user.visibleBrands[0].id,
        idUserCreator: this.$store.state.user.user.id,
        startTimes: newMessage.startTimes,
        endTimes: newMessage.endTimes,
        dayOfWeek: newMessage.dayOfWeek,
        dtException: newMessage.dtException,
        weather: newMessage.weather,
        SiteId: [currentSiteId],
        IdFile: newMessage.idFile,
        Intensity: newMessage.Intensity,
        idStream: -1,
        idSkeleton: -1,
      }

      try {
        this.sheduleSending = true
        const res = await messageService.createMessageSchedule(schedule)
        if (res && res.data) {
          this.alreadyUsed = false
          this.$toast.success('Le message a été modifié avec succès')
          this.$store.dispatch('message/_resetMessageState')
          this.$router.push({ name: 'messages' })
          window.scrollTo(0, 0)
        }
      } catch (err) {
        if (err.response.status === 416)
          this.$toast.error(
            `Limite dépassée dans la période selectionnée. Votre magasin a une limite de ${err.response.data.errorMessage} messages différents diffusés par jour.`
          )
        else if (err.response.status === 409) {
          this.$toast.error(
            'Le nom du message choisi est déjà utilisé, veuillez choisir un autre nom'
          )
          this.alreadyUsed = true
        } else
          this.$toast.error(
            "Une erreur s'est produite. Veuillez contacter le support technique ou réessayer ultérieurement."
          )
      } finally {
        this.sheduleSending = false
      }
    },
    formatDateFr(date) {
      //Already formated
      return moment(date).format('DD/MM/YYYY')
    },
    formatDateEn(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    startNowBtn() {
      this.startDateTrigger = 'now'
      this.startDateLocal = dateFormat.encodeServerDate(new Date())
      this.updateStartDate()
    },
    startLaterBtn() {
      this.startDateTrigger = 'later'
    },
    noEndBtn() {
      this.endDateTrigger = 'noEnd'
      this.endDateLocal = dateFormat.encodeServerDate('01/01/2099')
      this.updateEndDate()
    },
    endDateBtn() {
      this.endDateTrigger = 'date'
      if (
        !this.endDateLocal ||
        moment(this.endDateLocal).format('YYYY') == '2099' ||
        moment(this.endDateLocal).format('YYYY') == '2060'
      )
        this.endDateLocal = dateFormat.encodeServerDate(this.startDateLocal)
      else this.endDateLocal = dateFormat.encodeServerDate(this.endDateLocal)
    },
    startDateLabel() {
      const startDatefr = new Date(this.startDateLocal)
      startDatefr.setHours(0, 0, 0, 0)
      const todayDatefr = new Date()
      todayDatefr.setHours(0, 0, 0, 0)
      if (this.isEdit) {
        return startDatefr === todayDatefr
          ? this.$t('messages.chooseADate')
          : this.formatDateFr(this.startDateLocal)
      } else {
        return startDatefr > todayDatefr
          ? this.formatDateFr(this.startDateLocal)
          : this.$t('messages.chooseADate')
      }
    },
    endDateLabel() {
      if (
        moment(this.endDateLocal).format('YYYY') === '2099' ||
        moment(this.endDateLocal).format('YYYY') === '2060'
      )
        return this.$t('messages.chooseADate')
      else
        return this.endDateLocal
          ? this.formatDateFr(this.endDateLocal)
          : this.$t('messages.chooseADate')
    },
    dayClick(day, index) {
      day.selected = !day.selected
      if (day.selected) this.dayOfWeek += Math.pow(2, index)
      else this.dayOfWeek -= Math.pow(2, index)
    },
    changeExclusion(val) {
      this.exclusionLocal = val
    },
    changeTimeslots(val) {
      this.$store.dispatch('message/_setNewTimeSlot', val)
    },
    changeWeatherParams(val) {
      if (this.weather === -1 && val === 511) return
      else this.weather = val
    },
    updateName() {
      this.alreadyUsed = false
      this.messageName = this.messageNameLocal
    },
    updateStartDate() {
      this.startDate = this.startDateLocal
    },
    updateEndDate() {
      this.endDate = this.endDateLocal
    },
  },
}
</script>

<style lang="scss" scoped>
.default-select-date {
  width: 120px;
}
</style>
