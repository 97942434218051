import VueRouter from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  // {
  //   path: '/register-test',
  //   name: 'register-test',
  //   component: () => import('@/views/register.vue'),
  // },
  {
    path: '/register/:code/',
    name: 'register',
    component: () => import('@/views/register-franchise.vue'),
    props: true,
  },
  { path: '/', redirect: '/players' },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard.vue'),
  },
  {
    path: '/player/:id?',
    component: () => import('@/views/playersControl.vue'),
    name: 'player',
    props: true,
  },
  {
    path: '/player/:id/playlist',
    name: 'playlistSingle',
    component: () => import('@/views/playersPlaylist.vue'),
    props: true,
  },
  {
    path: '/players',
    component: () => import('@/views/playersRouter.vue'),
    meta: { breadCrumb: 'Players', name: 'players' },
    children: [
      {
        path: '',
        name: 'players',
        component: () => import('@/views/players.vue'),
      },
      {
        path: ':id/',
        name: 'control',
        meta: { breadCrumb: 'Player' },
        component: () => import('@/views/playersControl.vue'),
        props: true,
      },
      {
        path: ':id/playlist',
        name: 'playlist',
        meta: { breadCrumb: 'Playlist' },
        component: () => import('@/views/playersPlaylist.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/planification',
    name: 'planification',
    component: () => import('@/views/planification.vue'),
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/messages.vue'),
  },
  {
    path: '/schedule/:id',
    name: 'schedule-edition',
    component: () =>
      import('@/components/message/views/NewMessageSchedule.vue'),
  },
  {
    path: '/messages/new',
    component: () => import('@/components/message/views/MessagesRouter.vue'),
    children: [
      //message edit
      {
        path: '',
        name: '1',
        component: () => import('@/components/message/views/NewMessage.vue'),
        meta: { breadCrumb: 'Nouveau' },
      },
      //IA redaction + voice
      {
        path: 'editorial-assistant',
        name: 'ia/2',
        component: () =>
          import('@/components/message/views/NewMessageEditorial.vue'),
      },
      {
        path: 'voice',
        name: 'ia/3',
        component: () =>
          import('@/components/message/views/NewMessageVoice.vue'),
      },
      {
        path: 'schedule',
        name: 'ia/4',
        component: () =>
          import('@/components/message/views/NewMessageSchedule.vue'),
      },
      //only IA voice steps
      {
        path: 'voice',
        name: 'manual/2',
        component: () =>
          import('@/components/message/views/NewMessageVoice.vue'),
      },
      {
        path: 'schedule',
        name: 'manual/3',
        component: () =>
          import('@/components/message/views/NewMessageSchedule.vue'),
      },
      //Recorder steps
      {
        path: 'recorder',
        name: 'record/2',
        component: () => import('@/views/recorder.vue'),
      },
      {
        path: 'recorder/schedule/:id?',
        name: 'record/3',
        props: true,
        component: () => import('@/views/schedule.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.message.record.src || to.params.id) next()
          else next({ name: 'recorder' })
        },
      },
    ],
  },
  {
    path: '/compte',
    name: 'compte',
    component: () => import('@/views/profil.vue'),
  },
  {
    path: '/abonnement',
    name: 'abonnement',
    component: () => import('@/views/subscription.vue'),
  },
  {
    path: '/aide',
    name: 'aide',
    component: () => import('@/views/aide-support.vue'),
  },
  {
    path: '/design',
    name: 'design',
    component: () => import('@/views/designSystem.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/notFound.vue'),
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'login' &&
    to.name !== 'register-test' &&
    to.name !== 'register' &&
    to.name !== 'share' &&
    !store.getters['user/isAuthenticated']
  )
    next({ name: 'login' })
  else next()
})

export default router
