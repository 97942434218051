<template>
  <b-row>
    <b-col cols="12">
      <h-card class="mt-4 h-100">
        <b-row>
          <b-col v-if="messageRedactionIA" cols="12">
            <h4 class="mt-0 mb-0">
              {{ $t('messages.messageStepRedactionTitle') }}
            </h4>
            <h6 class="mt-4">
              {{ $t('messages.messageRedactionBrief') }}
            </h6>
            <textarea
              v-model="productDescription"
              class="w-100"
              :placeholder="$t('messages.messageRedactionExample')"
              rows="5"
            ></textarea>

            <h6 class="mt-3">
              {{ $t('messages.messageRedactionKeywords') }}
            </h6>
            <textarea
              v-model="keywords"
              class="w-100"
              :placeholder="$t('messages.messageRedactionKeywordsExample')"
              rows="2"
            ></textarea>

            <h6 class="mt-4">
              {{ $t('messages.messageRedactionPrice') }}
            </h6>
            <textarea
              v-model="price"
              class="w-100"
              :placeholder="$t('messages.messageRedactionPriceExample')"
              rows="2"
            ></textarea>

            <h6 class="mt-4">
              {{ $t('messages.messageRedactionTarget') }}
            </h6>
            <HButtonGroupSelect
              :value="target"
              :options="publicOptions"
              @select="selectedTarget"
            ></HButtonGroupSelect>
          </b-col>
        </b-row>
      </h-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      publicOptions: [
        { label: this.$t('messages.targetStudents'), value: 'students' },
        {
          label: this.$t('messages.targetYoungWorkers'),
          value: 'young adults',
        },
        { label: this.$t('messages.targetFamilies'), value: 'families' },
        { label: this.$t('messages.targetSeniors'), value: 'retired' },
        {
          label: this.$t('messages.targetProfessional'),
          value: 'Professionals',
        },
      ],
    }
  },
  activated() {
    this.$webSocketsConnect()
  },
  computed: {
    messageRedactionIA() {
      return !this.$store.state.message.newMessage.messageRedactionManual
    },
    messageGenerationState() {
      return this.$store.state.message.newMessage.messageGenerationState
    },
    price: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.price
      },
      // setter
      set: function(val) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'price',
          value: val,
        })
      },
    },
    target: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.target
      },
      // setter
      set: function(val) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'target',
          value: val,
        })
      },
    },
    keywords: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.keywords
      },
      // setter
      set: function(val) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'keywords',
          value: val,
        })
      },
    },
    productDescription: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.productDescription
      },
      // setter
      set: function(val) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'productDescription',
          value: val,
        })
      },
    },
    messagesContent: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.messagesContent
      },
      // setter
      set: function(message) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messagesContent',
          value: message,
        })
      },
    },
    allCompleted() {
      return (
        this.productDescription.length && this.price && this.selectedPublicValue
      )
    },
  },

  methods: {
    selectedTarget(val) {
      this.target = val
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid #ebeff6;
  padding: 8px;
  border-radius: 10px;
  &::placeholder {
    color: #a8aeb9;
  }
}
</style>
