<template>
  <b-container>
    <BreadCrumbsMessage :breadcrumbs="$route.matched" />
    <Transition :name="transitionName" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </Transition>
    <b-row class="mt-5 ">
      <b-col class="d-flex justify-content-between">
        <h-button @click="previousStep" outline red class="my-3">
          <b-icon
            :icon="currentStep === 1 ? 'x' : 'arrow-left'"
            class="mr-2"
          ></b-icon>
          {{ currentStep === 1 ? $t('actions.cancel') : 'Précédent' }}
        </h-button>
        <!-- //Next buttons for IA Creation Path -->
        <h-button
          v-if="messageType === 'ia' && !messageRedactionManual"
          :disabled="!validButtonMessageIA[currentStep - 1].isActive"
          @click="nextStep"
          green
          class="my-3 "
        >
          {{ validButtonMessageIA[currentStep - 1].label }}
          <b-icon icon="arrow-right" class="ml-2"></b-icon>
        </h-button>
        <!-- //Next buttons for Manual Creation Path -->
        <h-button
          v-else-if="messageType === 'ia' && messageRedactionManual"
          :disabled="!validButtonMessageManual[currentStep - 1].isActive"
          @click="nextStep"
          green
          class="my-3 "
        >
          {{ validButtonMessageManual[currentStep - 1].label }}
          <b-icon icon="arrow-right" class="ml-2"></b-icon>
        </h-button>

        <!-- //Next buttons for IA Record NOZ Path -->
        <h-button
          v-else-if="messageType === 'record'"
          :disabled="
            !validButtonMessageRecord[currentStep - 1].isActive ||
              messageWebSocketError
          "
          @click="nextStep"
          green
          class="my-3 "
        >
          {{ validButtonMessageRecord[currentStep - 1].label }}
          <b-icon icon="arrow-right" class="ml-2"></b-icon>
        </h-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BreadCrumbsMessage from '@/components/BreadCrumbsMessage'
import messageService from '@/services/messageService.js'
import dateFormat from '@/components/dateFormat'

import { mapState } from 'vuex'

export default {
  components: {
    BreadCrumbsMessage,
  },
  data() {
    return {
      currentStep: 1,
      transitionName: 'slide-left',
      alreadyUsed: false,
      scheduleSending: false,
      base64: null,
    }
  },
  computed: {
    hasAtLeastOneSelected() {
      return this.currentMessageCreation.messagesContent.some(
        (item) => item.selected === true
      )
    },
    validateVoiceButtonActive() {
      const newMessage = this.$store.state.message.newMessage
      const everyDone = newMessage.messageVoiceState.filter(
        (item) => item === 'done'
      )
      const selectedItems = this.currentMessageCreation.messagesContent.filter(
        (item) => item.selected === true
      )
      return everyDone.length === selectedItems.length
    },
    validateScheduleButtonActive() {
      const newMessage = this.$store.state.message.newMessage
      return newMessage.messageName.length > 4
    },
    allSelectedHaveLongText() {
      return this.currentMessageCreation.messagesContent
        .filter((item) => item?.selected === true)
        .every((item) => item?.text.length > 10)
    },
    ...mapState({
      currentMessageCreation: (state) => state.message.newMessage,
      messageType: (state) => state.message.newMessage.messageType,
      record: (state) => state.message.record,
      messageRedactionManual: (state) =>
        state.message.newMessage.messageRedactionManual,
      messageWebSocketError: (state) => state.message.messageWebSocketError,
    }),
    validButtonMessageIA() {
      return [
        {
          label: this.$t('actions.next'),
          isActive: this.currentMessageCreation.messageType.length,
        },
        {
          label:
            this.currentMessageCreation.messageGenerationState === 'done'
              ? this.$t('messages.regenerateMessagesContent')
              : this.$t('messages.generateMessagesContent'),
          isActive:
            this.currentMessageCreation.price &&
            this.currentMessageCreation.target &&
            this.currentMessageCreation.productDescription,
        },
        {
          label: this.$t('messages.validateVoices'),
          isActive: this.validateVoiceButtonActive,
        },
        {
          label: this.scheduleSending
            ? this.$t('login.loading')
            : this.$t('messages.validateAndScheduleMessages'),
          isActive: !this.scheduleSending && this.validateScheduleButtonActive,
        },
      ]
    },
    validButtonMessageManual() {
      return [
        {
          label: this.$t('actions.next'),
          isActive: this.currentMessageCreation.messageType.length,
        },
        {
          label: this.$t('messages.validateVoices'),
          isActive: this.validateVoiceButtonActive,
        },
        {
          label: this.scheduleSending
            ? this.$t('login.loading')
            : this.$t('messages.validateAndScheduleMessages'),
          isActive: !this.scheduleSending,
        },
      ]
    },
    validButtonMessageRecord() {
      return [
        {
          label: this.$t('actions.next'),
          isActive: this.currentMessageCreation.messageType.length,
        },
        {
          label: this.$t('messages.validateMessageContent'),
          isActive: this.$store.state.message.record.size > 0,
        },
        {
          label: this.scheduleSending
            ? this.$t('login.loading')
            : this.$t('messages.validateAndScheduleMessage'),
          isActive: !this.scheduleSending,
        },
      ]
    },
  },
  created() {
    const manual = this.$store.state.message.newMessage.messageRedactionManual
    let step = this.$route.name.split('/')[1]
      ? parseInt(this.$route.name.split('/')[1])
      : parseInt(this.$route.name)
    if (manual && step >= 4) step--
    this.currentStep = step
  },
  beforeDestroy: function() {
    this.$endWebsocket()
  },
  methods: {
    nextStep() {
      const path =
        this.messageType === 'ia'
          ? this.messageRedactionManual
            ? 'manual'
            : 'ia'
          : 'record'
      const stepsNumber =
        this.messageType === 'ia'
          ? this.messageRedactionManual
            ? this.validButtonMessageManual.length
            : this.validButtonMessageIA.length
          : this.validButtonMessageRecord.length
      if (this.currentStep < stepsNumber) {
        this.currentStep = this.currentStep + 1
        this.$router.push({ name: `${path}/${this.currentStep}` })
        if (this.currentStep === 3 && this.messageType == 'record') {
          this.blobToBase64()
        }
      } else {
        this.createMessageSchedule()
      }
    },
    previousStep() {
      if (this.currentStep === 1) {
        this.$router.push({ name: 'messages' })
        return
      }

      const path =
        this.messageType === 'ia'
          ? this.messageRedactionManual
            ? 'manual'
            : 'ia'
          : 'record'

      this.currentStep = this.currentStep - 1
      let routeName =
        this.currentStep === 1 ? '1' : `${path}/${this.currentStep}`
      this.$router.push({ name: routeName })
    },
    async getBase64FromUrl(url, index) {
      const data = await fetch(url)
      const blob = await data.blob()
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'size',
        value: blob.size,
        index: index,
      })
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1]
          resolve(base64data)
        }
      })
    },
    blobToBase64() {
      const blob = this.record.blob
      let reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = (event) => {
        const base64Local = event.target.result
        const base64Localsplit = base64Local.split(',')[1]
        this.base64 = base64Localsplit
      }
    },
    async createMessageSchedule() {
      const newMessage = this.$store.state.message.newMessage
      const currentSiteId = this.$store.state.user.selectedSite.id
      const fileuuid = newMessage.fileuuid

      if (this.messageType === 'record') {
        const schedule = {
          content: this.base64,
          size: this.record.size,
          idSchedule: -1,
          name: newMessage.messageName,
          startDate: dateFormat.encodeServerDate(newMessage.startDate),
          endDate: dateFormat.encodeServerDate(newMessage.endDate),
          frequency: 0,
          idTag: this.$store.state.message.mobileTags[0].id,
          idBrand: this.$store.state.user.user.visibleBrands[0].id,
        }
        this.createRecorderScheduleRequest(schedule)
        return
      }

      try {
        this.scheduleSending = true

        const promises = fileuuid.map(async (file, index) => {
          const fileToSend = newMessage.fileuuidComposit[index]
            ? newMessage.fileuuidComposit[index]
            : file
          const fileURL = `https://fg0.radioshop.net/file?uuid=${fileToSend}`
          const schedule = {
            active: true,
            content: await this.getBase64FromUrl(fileURL, index), // Récupérer le contenu en base64
            size: newMessage.size[index],
            scheduleId: -1,
            messageName: `${newMessage.messageName} - ${index + 1}`,
            startDate: dateFormat.encodeServerDate(newMessage.startDate),
            endDate: dateFormat.encodeServerDate(newMessage.endDate),
            frequency: newMessage.frequency,
            IdTagList: newMessage.idTagList,
            idBrand: this.$store.state.user.user.visibleBrands[0].id,
            idUserCreator: this.$store.state.user.user.id,
            startTimes: newMessage.startTimes,
            endTimes: newMessage.endTimes,
            dayOfWeek: newMessage.dayOfWeek,
            dtException: newMessage.dtException,
            weather: newMessage.weather,
            SiteId: [currentSiteId],
            Intensity: newMessage.Intensity,
            idStream: -1,
            idSkeleton: -1,
          }
          return messageService.createMessageSchedule(schedule)
        })

        // Waiting for all the promises to be resolved
        await Promise.all(promises)

        this.alreadyUsed = false
        this.$toast.success(this.$t('messages.scheduleSuccess'))
        this.$store.dispatch('message/_resetMessageState')
        this.$router.push({ name: 'messages' })
        window.scrollTo(0, 0)
      } catch (err) {
        if (err.response && err.response.status === 416) {
          this.$toast.error(
            `${this.$t('messages.messageLimitExceeded1')} ${
              err.response.data.errorMessage
            } ${this.$t('messages.messageLimitExceeded2')}`
          )
        } else if (err.response && err.response.status === 409) {
          this.$toast.error(this.$t('messages.nameAlreadyUsed'))
          this.alreadyUsed = true
        } else {
          this.$toast.error(this.$t('errors.generic'))
        }
      } finally {
        this.scheduleSending = false
      }
    },

    async createRecorderScheduleRequest(schedule) {
      try {
        this.scheduleSending = true
        const res = await messageService.createSchedule(schedule)
        if (res && res.data) {
          this.alreadyUsed = false
          this.$toast.success(this.$t('messages.messageScheduledSuccess'))
          this.$router.push({ name: 'messages' })
          window.scrollTo(0, 0)
        }
      } catch (err) {
        if (err.response.status === 416)
          this.$toast.error(
            `${this.$t('messages.messageLimitExceeded1')} ${
              err.response.data.errorMessage
            } ${this.$t('messages.messageLimitExceeded2')}`
          )
        else if (err.response.status === 409) {
          this.$toast.error(this.$t('errors.nameAlreadyUsed'))
          this.alreadyUsed = true
        } else this.$toast.error(this.$t('errors.generic'))
      } finally {
        this.scheduleSending = false
      }
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.name.split('/')[1] ? to.name.split('/')[1] : to.name
      const fromDepth = from.name.split('/')[1]
        ? from.name.split('/')[1]
        : from.name
      this.transitionName =
        parseInt(toDepth) < parseInt(fromDepth) ? 'slide-right' : 'slide-left'
    },
  },
}
</script>

<style lang="scss" scoped></style>
