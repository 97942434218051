import Vue from 'vue'
import musiqueService from '@/services/musiqueService'
const getDefaultState = () => {
  return {
    fetchingSchedules: false,
    fetchingPlaylistsPlayable: false,
    skeletonGroups: null,
    groupsLoaded: false,
    skeletons: [],
    streams: [],
    skeletonLoaded: false,
    sideBarOpt: false,
    selectedPlanning: null,
    editPlaylistMode: false,
    currentSkeleton: null,
    fetchingPlaylistState: false,
    duplicateTracks: null,
  }
}
const musique = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_FETCHINGSTATE(state, status) {
      state.fetchingSchedules = status
    },
    SET_FETCH_PLAYLIST_PLAYABLE_STATE(state, status) {
      state.fetchingPlaylistsPlayable = status
    },
    SET_SKELETON_GROUPS(state, groups) {
      state.skeletonGroups = groups
    },
    SET_SKELETONS(state, skeletons) {
      state.skeletons = skeletons
    },
    SET_STREAMS(state, streams) {
      state.streams = streams
    },
    PUSH_STREAM(state, streamToPush) {
      state.streams.push(streamToPush)
    },
    SET_GROUPS_LOADED(state, payload) {
      state.groupsLoaded = payload
    },
    SET_SKELETON_LOADED(state, payload) {
      state.skeletonLoaded = payload
    },
    SET_SIDEBAR_STATE(state, planning) {
      state.sideBarOpt = planning
    },
    SET_SELECTED_PLANNING(state, planning) {
      state.selectedPlanning = planning
    },
    SET_EDIT_PLAYLIST_MODE(state, payload) {
      state.editPlaylistMode = payload
    },
    SET_FETCH_PLAYLIST_EDIT(state, payload) {
      state.fetchingPlaylistState = payload
    },
    SET_CURRENT_SKELETON(state, payload) {
      state.currentSkeleton = payload
      state.skeletonEditMode = true
    },
    SET_CURRENT_SKELETON_NAME(state, payload) {
      state.currentSkeleton.name = payload
    },
    SET_CURRENT_SKELETON_COVER(state, payload) {
      state.currentSkeleton.coverDataUri = payload
    },
    REPLACE_CURRENT_SKELETONLINE_MATCH(state, payload) {
      state.currentSkeleton.skeletonLine[payload.index].idFile =
        payload.match.id
      state.currentSkeleton.skeletonLine[payload.index].foundStatus =
        'Identifié'
    },
    REMOVE_TRACK_ITEM_CURRENT_SKELETON(state, id) {
      let deleteItemIndex = state.currentSkeleton.skeletonLine.findIndex(
        (e) => e.idFile === id
      )
      if (deleteItemIndex > -1)
        Vue.set(
          state.currentSkeleton.skeletonLine[deleteItemIndex],
          'MyStatus',
          3
        )
    },
    ADD_TRACK_CURRENT_SKELETON(state, track) {
      state.currentSkeleton.skeletonLine.unshift(track)
    },
    SET_DUPLICATES_TRACKS(state, duplicates) {
      state.duplicateTracks = duplicates
    },
    SET_SKELETONLINE(state, skeletonLines) {
      state.currentSkeleton.skeletonLine = skeletonLines
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    _fetchingSchedulesState({ commit }, status) {
      commit('SET_FETCHINGSTATE', status)
    },
    async _getSkeletons({ commit, rootState }) {
      const params = {
        ididentity: rootState.user.user.visibleIdentities[0].id,
      }
      const res = await musiqueService.getSkeletonGroups(params)

      commit('SET_SKELETON_GROUPS', res.data)
      commit('SET_GROUPS_LOADED', true)

      const skeletonParams = {
        idIdentity: rootState.user.user.visibleIdentities[0].id,
        idBrand: rootState.user?.user?.visibleBrands[0].id,
        idSite: rootState.user.selectedSite.id,
      }

      const skeletonRes = await musiqueService.getSkeletons(skeletonParams)
      commit('SET_SKELETONS', skeletonRes.data)
      commit('SET_SKELETON_LOADED', true)
    },
    async _getStreams({ commit, rootState }) {
      const idBrand = rootState.user?.user?.visibleBrands[0].id
      const params = {
        ot: 1,
        idSite: rootState.user?.selectedSite?.id,
      }
      const streams = await musiqueService.getStreams(idBrand, params)
      const enableStreams = streams.data.filter((e) => e.enable === true)
      commit('SET_STREAMS', enableStreams)
    },
    _pushDefaultStream({ commit }) {
      const defaultStream = {
        idImage: -1,
        idStream: -1,
        name: 'Mon programme',
        type: 1,
      }
      commit('PUSH_STREAM', defaultStream)
    },
    _openCloseSideBar({ commit }, sideBarState) {
      commit('SET_SIDEBAR_STATE', sideBarState)
    },
    _openPlanning({ commit }, planning) {
      commit('SET_SELECTED_PLANNING', planning)
    },
    _createEditCurrentSkeleton({ commit }, skeleton) {
      commit('SET_CURRENT_SKELETON', skeleton)
    },
    _editCurrentSkeletonLines({ state, commit }, skeletonLinesToPush) {
      let currentSkeletonLocal = JSON.parse(
        JSON.stringify(state.currentSkeleton)
      )
      //keep previous ordering
      currentSkeletonLocal.skeletonLine.sort(
        (a, b) => a.lineNumber - b.lineNumber
      )
      //unshift new entries
      Array.prototype.unshift.apply(
        currentSkeletonLocal.skeletonLine,
        skeletonLinesToPush
      )
      commit('SET_CURRENT_SKELETON', currentSkeletonLocal)
    },
    _editCurrentSkeletonName({ state, commit }, value) {
      if (state.currentSkeleton) commit('SET_CURRENT_SKELETON_NAME', value)
    },
    _itemCurrentSkeletonItemMatch({ state, commit }, match) {
      let foundIndex = state.currentSkeleton.skeletonLine.findIndex(
        (e) => e.isrc === match.isrc
      )
      if (foundIndex < 0) {
        foundIndex = state.currentSkeleton.skeletonLine.findIndex(
          (e) => e.title.toUpperCase() === match.title.toUpperCase()
        )
      }
      const payload = {
        index: foundIndex,
        match: match,
      }
      if (foundIndex > -1) commit('REPLACE_CURRENT_SKELETONLINE_MATCH', payload)
    },
    _removeNotFoundFromCurrentSkeleton({ state, commit }) {
      let currentSkeletonLocal = JSON.parse(
        JSON.stringify(state.currentSkeleton)
      )
      currentSkeletonLocal.skeletonLine = currentSkeletonLocal.skeletonLine.filter(
        (e) => e.foundStatus !== 'loading'
      )
      commit('SET_CURRENT_SKELETON', currentSkeletonLocal)
    },
    _checkDuplicates({ state, commit }) {
      const currentSekelonLines = state.currentSkeleton.skeletonLine
      const mapCurrentSkeletonLines = currentSekelonLines.map((obj) => obj.isrc)
      const duplicates = mapCurrentSkeletonLines.filter(
        (e, i, a) => a.indexOf(e) !== i
      )

      commit('SET_DUPLICATES_TRACKS', duplicates)
    },
    _deleteDuplucates({ state, commit, dispatch }) {
      let currentSekelonLines = JSON.parse(
        JSON.stringify(state.currentSkeleton.skeletonLine)
      )
      const toRemove = state.duplicateTracks

      toRemove.forEach((trashIsrc) => {
        const i = currentSekelonLines.findIndex((e) => e.isrc === trashIsrc)
        currentSekelonLines.splice(i, 1)
      })

      commit('SET_SKELETONLINE', currentSekelonLines)

      dispatch('_checkDuplicates')
    },
    _resetMusiqueState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {
    skeletonOptions: (state) => {
      const skeletonOptionsMap =
        state.skeletons && state.skeletons.length > 0
          ? state.skeletons.map((obj) => {
              let rObj = {}
              rObj.name = obj.alias ? obj.alias : obj.name
              rObj.idImage = obj.idImageCover
              rObj.idSkeleton = obj.idSkeleton
              rObj.skeletonType = obj.skeletonType
              rObj.skeletonPlayable =
                obj.alias && obj.alias.length > 1
                  ? (obj.aliasFlags & (1 << 3)) > 0
                    ? true
                    : false
                  : (obj.flags & (1 << 3)) > 0
                  ? true
                  : false
              rObj.type = 1
              return rObj
            })
          : []
      return skeletonOptionsMap
    },
  },
}

export default musique
