<template>
  <b-row>
    <b-col cols="12" md="12" :lg="hasIA && hasRecord ? 4 : 6">
      <h-card
        v-if="hasIA"
        class="mt-4 h-100"
        isClickable
        @click="setMessageType('voice')"
        :isActive="messageType === 'ia' && messageRedactionManual"
      >
        <img
          src="@/assets/svg/illu_voice_message.svg"
          height="140px"
          alt="ia message"
          class="mx-auto mt-3"
        />
        <h4 class="text-center mt-5 mb-0">
          {{ $t('messages.messageRecordedBy') }} <br />
          {{ $t('messages.iaVoice') }}
        </h4>
        <h6 class="text-center mt-2">
          {{ $t('messages.iaVoiceDescriptionTitle') }}
        </h6>
        <p class="mt-3">
          {{ $t('messages.iaVoiceDescriptionText') }}
        </p>
      </h-card>
    </b-col>
    <b-col
      cols="12"
      md="12"
      :lg="hasIA && hasRecord ? 4 : 6"
      class="mt-2 mt-lg-0"
    >
      <h-card
        v-if="hasIA"
        class="mt-4 h-100"
        isClickable
        @click="setMessageType('ia')"
        :isActive="messageType === 'ia' && !messageRedactionManual"
      >
        <img
          src="@/assets/svg/illu_ia_message.svg"
          height="140px"
          alt="ia message"
          class="mx-auto mt-3"
        />
        <h4 class="text-center mt-5 mb-0">
          {{ $t('messages.messageTextAndVoiceIA') }}
        </h4>
        <h6 class="text-center mt-2">
          {{ $t('messages.iaTextVoiceDescriptionTitle') }}
        </h6>
        <p class="mt-3">
          {{ $t('messages.iaTextVoiceDescriptionText') }}
        </p>
      </h-card>
    </b-col>
    <b-col
      cols="12"
      md="12"
      :lg="hasIA && hasRecord ? 4 : 12"
      class="mt-2 mt-lg-0"
    >
      <h-card
        v-if="hasRecord"
        class="mt-4 h-100"
        isClickable
        @click="setMessageType('record')"
        :isActive="messageType === 'record'"
      >
        <img
          src="@/assets/svg/illu_record_message.svg"
          height="140px"
          alt="ia message"
          class="mx-auto mt-3"
        />
        <h4 class="text-center mt-5 mb-0">
          {{ $t('messages.autoRecordMessage') }}
        </h4>
        <h6 class="text-center mt-2">
          {{ $t('messages.recordMessageDescriptionTitle') }}
        </h6>
        <p class="mt-3">
          {{ $t('messages.recordMessageDescriptionText') }}
        </p>
      </h-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    messageRedactionManual() {
      return this.$store.state.message.newMessage.messageRedactionManual
    },
    messageType: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.messageType
      },
      // setter
      set: function(type) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messageType',
          value: type,
        })
      },
    },
    hasIA() {
      return this.rights?.includes('MYHUBCAST_TAB_MESSAGE_AI')
    },
    hasRecord() {
      return this.rights?.includes('MYHUBCAST_TAB_MESSAGE_RECORDING')
    },
    ...mapState({
      rights: (state) => state.user.user?.rights,
    }),
  },
  methods: {
    setMessageType(type) {
      const isVoice = type === 'voice'
      const messageRedactionLocal = isVoice ? true : false

      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageRedactionManual',
        value: messageRedactionLocal,
      })

      this.messageType = isVoice ? 'ia' : type
    },
  },
  created() {
    this.$store.dispatch('message/_resetMessageState')
    if (this.hasRecord && !this.hasIA) this.setMessageType('record')
  },
  activated() {
    this.$endWebsocket()
    this.$store.dispatch('message/_resetMessageState')
  },
}
</script>

<style lang="scss" scoped>
h6 {
  color: #848c9c;
}
p {
  color: #70798b;
}
</style>
