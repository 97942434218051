<template>
  <b-col cols="12">
    <h-card>
      <div class="d-flex justify-content-between w-100">
        <div
          class="d-flex align-items-center flex-grow"
          v-b-toggle="'messageVoice' + index"
        >
          <b-icon
            :icon="
              messageCollapse.ite[index]
                ? 'dash-circle-fill'
                : 'plus-circle-fill'
            "
            class="mr-3 mt-1"
          ></b-icon>
          <h5 class="h5 mb-3">{{ $t('messages.message') }} {{ index + 1 }}</h5>
        </div>
        <div
          class="d-flex align-items-center py-2 audio-balise"
          v-if="messageVoiceState[index] === 'done'"
        >
          <audio
            class="w-100"
            ref="audioVoice"
            :src="audioSRC(index)"
            controls
          ></audio>
        </div>
        <div
          v-else-if="messageVoiceState[index] === 'running'"
          class="d-flex align-items-center py-2"
          :key="'running'"
        >
          <p class="ml-3 mt-3">
            {{ $t('messages.generatingAudio') }}
          </p>
        </div>
        <div
          v-else-if="messageVoiceState[index] === 'compositing'"
          class="d-flex align-items-center py-2"
          :key="'compositing'"
        >
          <p class="ml-3 mt-3">
            {{ $t('messages.compositingRunning') }}
          </p>
        </div>
        <h-button
          v-if="
            messageVoiceState[index] !== 'done' ||
              (messageVoiceState[index] === 'done' &&
                selectedCompositing[index] &&
                selectedCompositing[index].id >= 0)
          "
          :disabled="
            messageVoiceState[index] === 'running' ||
              messageVoiceState[index] === 'compositing' ||
              (!voiceGender[index] &&
                compositingOptions &&
                compositingOptions.length <= 1) ||
              (!voiceGender[index] &&
                !selectedCompositing[index] &&
                compositingOptions &&
                compositingOptions.length > 1)
          "
          @click.native.prevent="
            (event) =>
              messageVoiceState[index] === 'done' && !voiceGeneration[index]
                ? generateComposer(event, index)
                : generateVoiceMessage(event, index)
          "
          blue
          outline
          class="align-self-center"
        >
          <b-icon icon="soundwave" class="w-17px mr-2" font-scale="1"> </b-icon>
          {{
            messageVoiceState[index] === 'done'
              ? $t('messages.regenerateVoiceBtn')
              : $t('messages.generateVoiceBtn')
          }}
        </h-button>
      </div>

      <b-collapse
        v-model="messageCollapse.ite[index]"
        :id="'messageVoice' + index"
        class="px-4 pb-3"
      >
        <b-row>
          <b-col cols="12" class="my-3">
            <textarea
              :value="messagesContent[index].text"
              @input="updateText(index, $event.target.value)"
              class=" w-100 h-100"
              :disabled="
                messageGenerationState === 'running' ||
                  !messagesContent[index].selected
              "
              rows="4"
            ></textarea>
          </b-col>
          <b-col md="5" class="mb-4">
            <h5 class="mb-2">{{ $t('messages.voiceParams') }}</h5>
            <b-row>
              <b-col cols="12" md="12" lg="6">
                <h-card
                  class=" mt-4 h-100 "
                  isClickable
                  @click="setVoiceGender(index, 'woman')"
                  :isActive="
                    voiceGender[index] && voiceGender[index] === 'woman'
                  "
                >
                  <img
                    src="@/assets/svg/illu_woman_voice.svg"
                    height="80px"
                    alt="ia message"
                  />
                  <h5 class="text-center mt-4 mb-0">
                    {{ $t('messages.womanVoice') }}
                  </h5>
                </h-card>
              </b-col>
              <b-col cols="12" md="12" lg="6" class="mt-3 mt-lg-0">
                <h-card
                  class="mt-4 h-100"
                  isClickable
                  @click="setVoiceGender(index, 'man')"
                  :isActive="voiceGender[index] && voiceGender[index] === 'man'"
                >
                  <img
                    src="@/assets/svg/illu_man_voice.svg"
                    height="80px"
                    alt="ia message"
                    class="mx-auto"
                  />
                  <h5 class="text-center mt-4 mb-0">
                    {{ $t('messages.manVoice') }}
                  </h5>
                </h-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="7"
            class="mb-4"
            v-if="compositingOptions && compositingOptions.length > 1"
          >
            <h5 class="mb-2">{{ $t('messages.compositingOptions') }}</h5>
            <div class="margin-comp">
              <h-select
                class="mt-2"
                :value="
                  selectedCompositing[index]
                    ? selectedCompositing[index]
                    : updateSelectedCompositing(index, compositingOptions[1])
                "
                @input="updateSelectedCompositing(index, $event)"
                :placeholder="$t('messages.compositingChoiceLabel')"
                left-icon="input-cursor"
                :options="compositingOptions"
                notClearable
              ></h-select>
            </div>
            <b-col
              cols="12"
              class="mt-3"
              v-if="
                selectedCompositing[index] && selectedCompositing[index].id > 0
              "
            >
              <p>
                {{ $t('messages.compositingExplain1') }}
                <br />
                {{ $t('messages.compositingExplain1') }}
              </p>
              <MessageCompositing
                :voiceVolume="voiceVolume[index]"
                :introVolume="introVolume[index]"
                :fondVolume="bedVolume[index]"
                :outroVolume="outroVolume[index]"
              />
              <div>
                <b-row>
                  <b-col md="6">
                    <h6>{{ $t('messages.volumeManaging') }}</h6>
                    <div class="d-flex ">
                      <div class="d-flex flex-column align-items-center mx-3">
                        <vue-slider
                          :min="0"
                          :value="introVolume[index]"
                          @change="(value) => setIntroVolume(value, index)"
                          direction="btt"
                          :height="150"
                        />
                        <p class="pt-2">{{ $t('messages.intro') }}</p>
                      </div>
                      <div class="d-flex flex-column align-items-center mx-3">
                        <vue-slider
                          :min="0"
                          :value="voiceVolume[index]"
                          @change="(value) => setVoiceVolume(value, index)"
                          direction="btt"
                          :height="150"
                        />
                        <p class="pt-2">{{ $t('messages.voice') }}</p>
                      </div>
                      <div class="d-flex flex-column align-items-center mx-3">
                        <vue-slider
                          :min="0"
                          :value="bedVolume[index]"
                          @change="(value) => setBedVolume(value, index)"
                          direction="btt"
                          :height="150"
                        />
                        <p class="pt-2">{{ $t('messages.bed') }}</p>
                      </div>
                      <div class="d-flex flex-column align-items-center mx-3">
                        <vue-slider
                          :min="0"
                          :value="outroVolume[index]"
                          @change="(value) => setOutroVolume(value, index)"
                          direction="btt"
                          :height="150"
                        />
                        <p class="pt-2">{{ $t('messages.outro') }}</p>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" class="d-flex flex-column">
                    <h6>{{ $t('messages.offsetManagement') }}</h6>
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-column">
                        <p class="mb-0">{{ $t('messages.bed') }}</p>
                        <vue-slider
                          class="pl-1"
                          :min="-2000"
                          :max="2000"
                          :interval="100"
                          :marks="marks"
                          :value="bedNudge[index]"
                          @change="(value) => setBedNudge(value, index)"
                          :process="false"
                        />
                      </div>
                      <p class="mt-4 mb-0">{{ $t('messages.voice') }}</p>
                      <vue-slider
                        class="pl-1"
                        :min="-2000"
                        :max="2000"
                        :marks="marks"
                        :interval="100"
                        :value="voiceNudge[index]"
                        @change="(value) => setVoiceNudge(value, index)"
                        :process="false"
                      />
                      <p class="mt-4 mb-0">{{ $t('messages.outro') }}</p>
                      <vue-slider
                        class="pl-1"
                        :min="-2000"
                        :max="2000"
                        :marks="marks"
                        :interval="100"
                        :value="outroNudge[index]"
                        @change="(value) => setOutroNudge(value, index)"
                        :process="false"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </b-collapse>
    </h-card>
  </b-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MessageCompositing from '@/components/message/MessageCompositing'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    VueSlider,
    MessageCompositing,
  },
  props: {
    index: Number,
    message: Object,
  },
  data() {
    return {
      messageCollapse: {
        ite: [true],
      },
      marks: [-2000, -1000, 0, 1000, 2000],
      voiceGeneration: [true, true, true, true, true],
    }
  },
  computed: {
    ...mapState({
      messageGenerationState: (state) =>
        state.message.newMessage.messageGenerationState,
      currentMessage: (state) => state.message.newMessage,
      messageRedactionIA: (state) =>
        !state.message.newMessage.messageRedactionManual,
      newMessageComp: (state) => state.message.newMessage,
      messageVoiceState: (state) => state.message.newMessage.messageVoiceState,
    }),
    selectedCompositing() {
      return this.$store.state.message.newMessage.selectedCompositing
    },
    voiceGender: {
      // getter
      get() {
        return this.$store.state.message.newMessage.voiceGender
      },
      // setter
      set({ index, gender }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'voiceGender',
          value: gender,
          index: index,
        })
      },
    },
    introVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume0
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume0',
          value: vol,
          index: index,
        })
      },
    },
    bedVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume1
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume1',
          value: vol,
          index: index,
        })
      },
    },
    outroVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume2
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume2',
          value: vol,
          index: index,
        })
      },
    },
    voiceVolume: {
      get() {
        return this.$store.state.message.newMessage.Volume3
      },
      set({ index, vol }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Volume3',
          value: vol,
          index: index,
        })
      },
    },
    introNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge0
      },
      set(nudge) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge0',
          value: nudge,
        })
      },
    },
    bedNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge1
      },
      set({ nudge, index }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge1',
          value: nudge,
          index: index,
        })
      },
    },
    outroNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge2
      },
      set({ nudge, index }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge2',
          value: nudge,
          index: index,
        })
      },
    },
    voiceNudge: {
      get() {
        return this.$store.state.message.newMessage.Nudge3
      },
      set({ index, nudge }) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'Nudge3',
          value: nudge,
          index: index,
        })
      },
    },
    messagesContent: {
      // getter
      get: function() {
        return this.$store.state.message.newMessage.messagesContent
      },
      // setter
      set: function(message) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messagesContent',
          value: message,
        })
      },
    },
    compositingOptions() {
      const compositingOptionsStore = this.$store.state.message
        .compositingOptions

      if (!compositingOptionsStore) return
      const compositingForSelect = compositingOptionsStore.map((item) => {
        return {
          label: item.name,
          id: item.id,
        }
      })
      return compositingForSelect
    },
  },
  methods: {
    ...mapMutations('message', {
      updateMessageText: 'UPDATE_MESSAGE_TEXT',
      updateMessageSelected: 'UPDATE_MESSAGE_SELECTED',
    }),
    updateSelected(index, selected) {
      this.updateMessageSelected({ index, selected })
    },
    updateText(index, text) {
      this.voiceGeneration[index] = true
      this.updateMessageText({ index, text })
    },
    setVoiceGender(index, gender) {
      this.voiceGeneration[index] = true
      this.voiceGender = { index: index, gender: gender }
    },
    updateSelectedCompositing(index, value) {
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'selectedCompositing',
        value,
        index,
      })

      this.$store.dispatch('message/_setNudgeVolumeData', {
        index: index,
        id: value.id,
      })
    },
    setIntroVolume(value, index) {
      this.introVolume = { index: index, vol: value }
    },
    setVoiceVolume(value, index) {
      this.voiceVolume = { index: index, vol: value }
    },
    setBedVolume(value, index) {
      this.bedVolume = { index: index, vol: value }
    },
    setOutroVolume(value, index) {
      this.outroVolume = { index: index, vol: value }
    },
    setBedNudge(value, index) {
      this.bedNudge = { index: index, nudge: value }
    },
    setVoiceNudge(value, index) {
      this.voiceNudge = { index: index, nudge: value }
    },
    setOutroNudge(value, index) {
      this.outroNudge = { index: index, nudge: value }
    },
    generateVoiceMessage(event, index) {
      event.stopPropagation()
      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'fileuuid',
        value: null,
        index: index,
      })

      this.$store.dispatch('message/_setNewMessageProp', {
        key: 'messageVoiceState',
        value: 'running',
        index: index,
      })

      if (!this.messagesContent) return
      const generateVoice = JSON.stringify({
        Content: {
          IdTransaction: this.$store.state.message.newMessage.idTransaction,
          Parameters: {
            Text: this.messagesContent[index].text,
            Voice: this.voiceGender[index],
          },
          Provider: 'ElevenLabs',
        },
        Header: {
          Type: 'ProviderRequest',
          idbrand: this.$store.state.user.user.visibleBrands[0].id,
          Tag: `ElevenLabs-${index}`,
        },
      })
      this.voiceGeneration[index] = false
      this.$webSocketsSend(generateVoice)
    },
    fileuuidFromWs(index) {
      if (this.newMessageComp.fileuuidComposit[index])
        return this.newMessageComp.fileuuidComposit[index]
      else return this.newMessageComp.fileuuid[index]
    },
    audioSRC(index) {
      return `https://fg0.radioshop.net/file?uuid=${this.fileuuidFromWs(index)}`
    },
    updateAudioSource() {
      const audioElement = this.$refs.audioElement

      if (audioElement) {
        audioElement.pause()
        audioElement.currentTime = 0
        audioElement.src = this.audioSRC
        audioElement.load()
        audioElement.play()
      }
    },
    generateComposer(event, index) {
      event.stopPropagation()
      if (!this.selectedCompositing[index])
        this.selectedCompositing[index] = {
          name: this.$t('messages.noCompositing'),
          id: 0,
        }
      if (this.selectedCompositing[index].id < 1) {
        this.$removeCompositing(index)
        return
      }
      const fileUuid = this.$store.state.message.newMessage.fileuuid[index]
      this.voiceGeneration[index] = false
      this.$sendCompositing(fileUuid, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.audio-balise {
  width: 400px;
}

textarea {
  border: 1px solid #ebeff6;
  padding: 8px;
  transition: 0.3s;
  border-radius: 10px;
  resize: none;
  &::placeholder {
    color: #a8aeb9;
  }
}
</style>
