<template>
  <div>
    <h5 class="font-weight-semi-bold mb-0 mt-2">
      {{ $t('planification.addTitles') }}
    </h5>
    <div class="search-field-group mt-3">
      <h-input
        v-model="search"
        @keyup.enter="searchNameArtist"
        @input="timerSearchFn"
        type="text"
        size="lg"
        :placeholder="$t('planification.searchForTitleArtist')"
        leftIcon="search"
        class="flex-grow-1"
      >
      </h-input>
      <div
        v-if="showResults"
        class="searchResults"
        v-click-outside="hideResults"
      >
        <template v-if="searching">
          <div
            class="py-2 text-center d-flex align-items-center justify-content-center"
          >
            <div class="spinner-border text-secondary mr-2" role="status"></div>
            {{ $t('planification.researchInProgress') }}
          </div>
        </template>
        <template v-else>
          <template v-if="searchResults && searchResults.length">
            <div
              v-for="(searchResult, index) in searchResults"
              :key="index"
              class="d-flex align-items-center mb-1"
            >
              <div
                v-if="searchResult"
                class="song-title mx-2 font-weight-semi-bold"
              >
                <img
                  v-if="searchResult.id"
                  :src="albumCover(searchResult.id)"
                  alt="track cover"
                  class="mr-2"
                  width="36"
                  height="36"
                  @error="imageLoadError"
                />
              </div>
              <div
                v-if="searchResult"
                class="d-flex flex-grow-1 song-title-full"
              >
                <span>
                  <span
                    v-for="(word, index) in searchResultComp(searchResult)"
                    :key="index"
                    :class="{
                      'font-weight-semi-bold': search
                        .toUpperCase()
                        .includes(word),
                    }"
                    >{{ word }}
                  </span>
                </span>
              </div>
              <h-button
                @click="addTrackToPlaylist(searchResult)"
                blue
                flat
                size="sm"
                class="add-btn"
              >
                <b-icon icon="plus"></b-icon>{{ $t('actions.add') }}</h-button
              >
            </div>
          </template>
          <div v-else class="py-2 text-center">
            {{ $t('planification.noResult') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import musiqueService from '@/services/musiqueService'
export default {
  data() {
    return {
      search: '',
      timerSearch: null,
      searchResults: null,
      showResults: false,
      searching: false,
    }
  },
  methods: {
    albumCover(idImage) {
      if (idImage)
        return `https://fileapi.radioshop.net/getcover.aspx?idfile=${idImage}&ft=1200`
      else return null
    },
    imageLoadError() {
      const img = require(`@/assets/svg/default-cover.svg`)
      event.target.src = img
    },
    hideResults() {
      this.showResults = false
    },
    timerSearchFn(val) {
      if (val.length > 2) {
        clearTimeout(this.timerSearch)
        this.timerSearch = setTimeout(() => {
          this.showResults = true
          this.searchNameArtist()
        }, 500)
      } else this.searchResults = null
    },
    async searchNameArtist() {
      clearTimeout(this.timerSearch)
      const params = {
        SubType: 1,
        TagList: [],
        User: -1,
        CountRow: 50,
        Direction: 'ASC',
        IDLibrary: 1,
        IDLibraryExclude: -1,
        Identity: -1,
        OrderBy: 'Nom',
        StartRow: 0,
        Name: this.search,
      }
      if (!this.searching && this.search.length) {
        this.showResults = true
        this.searching = true
        const results = await musiqueService.searchTrackCumulative(params)
        this.searchResults = results.data
        this.searching = false
      }
    },
    addTrackToPlaylist(track) {
      const trackFormat = {
        albumCover: this.albumCover(track.id),
        artists: track.artists.map((i) => i).join(' & '),
        foundStatus: this.$t('planification.songIdentified'),
        idFile: track.id,
        idSkeleton: this.currentSkeleton.idSkeleton,
        idSkeletonLine: -1,
        isrc: track.isrc,
        lineNumber: null,
        skeletonLinetype: 2,
        title: track.title,
        MyStatus: 2,
      }
      this.$store.commit('musique/ADD_TRACK_CURRENT_SKELETON', trackFormat)
      this.$store.dispatch('musique/_removeNotFoundFromCurrentSkeleton')
      this.$store.dispatch('musique/_checkDuplicates')
    },
    searchResultComp(result) {
      const restult = `${result.title} - ${result.artists
        .map((i) => i)
        .join(' & ')}`
      return restult.split(' ')
    },
  },
  computed: {
    currentSkeleton() {
      return this.$store.state.musique.currentSkeleton
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
}

.search-field-group {
  height: 100%;
  position: relative;
  z-index: 2;
}
.add-btn {
  white-space: nowrap;
}
.song-title-full {
  min-width: 0;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.searchResults {
  width: 100%;
  min-width: 500px;
  background: white;
  z-index: 10;
  position: absolute;
  padding: 10px 5px;
  border-radius: 5px;
  top: 48px;
  left: 0;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 0 4px $h-box-shadow;
}
</style>
