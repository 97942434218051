import messageService from '@/services/messageService.js'
import Vue from 'vue'
const getDefaultState = () => {
  return {
    messageWebSocketError: false,
    record: {},
    tags: null,
    mobileTags: null,
    doneTagReq: false,
    mediaMessages: [],
    messagesLoaded: false,
    compositingOptions: null,
    newMessage: {
      //Message transaction
      idTransaction: '',
      messageType: '',
      messageRedactionManual: false,
      price: null,
      target: null,
      keywords: [],
      productDescription: [],
      voiceGender: [],
      messageGenerationState: '',
      messagesContent: [],
      messageVoiceState: [],
      fileuuid: [],
      fileuuidComposit: [],
      idFile: [], //for Edit
      selectedCompositing: [],
      //Nudges & volumes
      Nudge0: [],
      Nudge1: [],
      Nudge2: [],
      Nudge3: [],
      Volume0: [],
      Volume1: [],
      Volume2: [],
      Volume3: [],
      //Message Data
      idSchedule: null,
      messageName: '',
      size: [],
      startDate: null,
      endDate: null,
      idTagList: [],
      startTimes: null,
      endTimes: null,
      dayOfWeek: 127,
      dtException: [],
      weather: 511,
      intensity: null,
    },
  }
}
const message = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_MESSAGE_LOADED(state, payload) {
      state.messagesLoaded = payload
    },
    SET_MEDIA(state, messages) {
      state.mediaMessages = messages
      state.messagesLoaded = true
    },
    SET_RECORD(state, record) {
      state.record = Object.assign({}, state.record, record)
    },
    SET_TAGS(state, tags) {
      state.tags = tags
      state.doneTagReq = true
    },
    SET_MOBILE_TAGS(state, tags) {
      state.mobileTags = tags
      state.doneTagReq = true
    },
    START_TAG_REQ(state) {
      state.doneTagReq = false
    },
    CLEAR_RECORD(state) {
      state.record = {}
    },
    SET_NEW_MESSAGE_KEY(state, { key, index, value }) {
      if (!state.newMessage[key] && index) {
        Vue.set(state.newMessage, key, []) // Assurer que la clé existe
      }
      if (typeof index !== 'undefined' && index !== null) {
        Vue.set(state.newMessage[key], index, value) // Assurer la réactivité pour un tableau
      } else {
        Vue.set(state.newMessage, key, value) // Assurer la réactivité pour un objet
      }
    },
    UPDATE_MESSAGE_SELECTED(state, { index, selected }) {
      state.newMessage.messagesContent[index].selected = selected
    },
    UPDATE_MESSAGE_TEXT(state, { index, text }) {
      state.newMessage.messagesContent[index].text = text
    },
    SET_NEW_EXCLUSION(state, value) {
      state.newMessage.dtException = [...value]
    },
    SET_NEW_TIMESLOT(state, value) {
      const startTimesMap = value.map((slot) => slot.start)
      state.newMessage.startTimes = [...startTimesMap]
      const endTimesMap = value.map((slot) => slot.end)
      state.newMessage.endTimes = [...endTimesMap]
    },
    SET_SELECTED_TAGS(state, ids) {
      state.newMessage.idTagList = ids
    },
    SET_INTENSITY(state, intensity) {
      state.newMessage.intensity = intensity
    },
    SET_COMPOSITING_OPTIONS(state, options) {
      state.compositingOptions = options
      const noCompositingOption = {
        name: 'Aucune composition',
        id: 0,
      }
      state.compositingOptions.unshift(noCompositingOption)
    },
    SET_DEFAULT_COMPOSITING(state) {
      for (let i = 0; i < 5; i++) {
        state.newMessage.selectedCompositing.push({
          name: 'Aucune composition',
          id: 0,
        })
      }
    },
    SET_COMPOSITING_PARTS(state, { index, parts }) {
      parts.forEach((part, i) => {
        let propertyNudge = `Nudge${i}`
        let propertyVolume = `Volume${i}`
        if (!state.newMessage[propertyNudge]) {
          state.newMessage[propertyNudge] = []
        }
        if (!state.newMessage[propertyVolume]) {
          state.newMessage[propertyVolume] = []
        }

        state.newMessage[propertyNudge][index] = part.nudge
        state.newMessage[propertyVolume][index] = part.volume
      })
    },
    MATCH_SCHEDULE(state, payload) {
      const mergedObj = { ...state.newMessage, ...payload }
      state.newMessage = mergedObj
    },
    SET_WEBSOCKET_ERROR(state, payload) {
      state.messageWebSocketError = payload
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async _getMessages({ commit, rootState }, reqParams) {
      if (!rootState.user.user) return
      const params = {
        IdBrand: rootState.user.user.visibleBrands[0].id,
        TypeMedia: 5,
        IdSite: rootState.user.selectedSite.id,
        IdStream: -1,
        FilterType: reqParams && reqParams.status ? reqParams.status.value : 5,
        RowCount: 100, // max items
        RowNum: reqParams && reqParams.pageNumber ? reqParams.pageNumber : 0,
      }
      const res = await messageService.getMediaMessages(params)
      commit('SET_MEDIA', res.data)
    },
    _saveMessage({ commit }, audioRecord) {
      commit('SET_RECORD', audioRecord)
    },
    _clearMessage({ commit }) {
      commit('CLEAR_RECORD')
    },
    async _getTags({ commit, rootState }) {
      const res = await messageService.getTags(
        rootState.user.user.visibleBrands[0].id
      )
      commit('SET_TAGS', res.data)
    },
    async _getMobileTags({ commit, rootState }) {
      commit('START_TAG_REQ')
      const res = await messageService.getMobileTags(
        rootState.user.user.visibleBrands[0].id
      )
      commit('SET_MOBILE_TAGS', res.data)
    },
    _setNewMessageProp({ commit }, payload) {
      commit('SET_NEW_MESSAGE_KEY', payload)
    },
    _setNewdtExclusion({ commit }, payload) {
      commit('SET_NEW_EXCLUSION', payload)
    },
    _setNewTimeSlot({ commit }, payload) {
      commit('SET_NEW_TIMESLOT', payload)
    },
    _setSelectedTags({ commit }, payload) {
      commit('SET_SELECTED_TAGS', payload)
    },
    _setIntensity({ commit }, payload) {
      commit('SET_INTENSITY', payload)
    },
    async _getCompositingOptions({ commit, rootState }) {
      if (rootState.message.compositingOptions) return
      const res = await messageService.getCompositingOptions(
        rootState.user.user.visibleBrands[0].id
      )

      commit('SET_COMPOSITING_OPTIONS', res.data)
      if (res.data.length === 0) {
        commit('SET_DEFAULT_COMPOSITING')
      }
    },
    _setNudgeVolumeData({ state, commit }, { index, id }) {
      if (id === -1) return
      const parts = state.compositingOptions.find((e) => e.id === id).parts
      if (!parts) return
      commit('SET_COMPOSITING_PARTS', { index, parts })
    },
    _matchEditSchedule({ commit }, schedule) {
      let scheduleEdit = {
        idSchedule: schedule.scheduleId,
        messageName: schedule.title,
        size: [],
        idFile: schedule.idFile,
        startDate: new Date(schedule.startDate),
        endDate: new Date(schedule.endDate),
        idTagList: [],
        startTimes: null,
        endTimes: null,
        dayOfWeek: schedule.dayOfWeek,
        dtException: schedule.dtException,
        weather: schedule.weather,
        intensity: schedule.intensity,
      }

      if (schedule.schedTime) {
        scheduleEdit.startTimes = []
        scheduleEdit.endTimes = []
        schedule.schedTime.forEach((sched) => {
          const split = sched.formatedScheduleTime.split('>')
          scheduleEdit.startTimes.push(split[0].trim())
          scheduleEdit.endTimes.push(split[1].trim())
        })
      }
      commit('RESET_STATE')
      commit('MATCH_SCHEDULE', scheduleEdit)
    },
    _setWebsocketError({ commit }, payload) {
      commit('SET_WEBSOCKET_ERROR', payload)
    },
    _resetMessageState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {},
}

export default message
