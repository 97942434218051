<template>
  <h-card>
    <b-row class="d-block d-lg-none">
      <b-col class="d-flex align-items-center mb-3">
        <h4 class="mt-0 mb-0">{{ $t('player.filters') }}</h4>
        <b-icon
          @click="collapsedFilters = !collapsedFilters"
          icon="chevron-up"
          class="ml-auto"
          :class="{ rotate: !collapsedFilters, out: collapsedFilters }"
          scale="1.5"
        ></b-icon>
      </b-col>
    </b-row>
    <b-row class="filters-row" :class="{ collapsed: collapsedFilters }">
      <b-col class="flex-grow-1 pb-0" md="12" lg>
        <b-row>
          <b-col lg="6" xl="6" class="pr-lg-0">
            <h-input
              v-model="search"
              :value-prop="search"
              size="lg"
              :placeholder="$t('player.search')"
              left-icon="search"
              @input="searchEvent"
            ></h-input>
          </b-col>
          <b-col lg="6" xl="6" class="mt-2 mt-lg-0 pr-lg-0">
            <h-select
              v-model="selectedStatus"
              placeholder="Statut de diffusion"
              left-icon="megaphone"
              :options="statusOptions"
              :selectedTags="selectedStatus"
              notClearable
            ></h-select>
          </b-col>
        </b-row>
      </b-col>
      <div class="flex-shrink-1 btn-filter-container">
        <h-button @click="filter" blue outline class="filter-btn mx-3 mt-lg-0">
          <b-icon icon="filter" class="mr-2"></b-icon>{{ $t('player.filter') }}
        </h-button>
        <router-link class="d-none d-lg-inline" :to="{ name: '1' }">
          <h-button blue id="RecordButton">
            <b-icon icon="plus-circle-fill" class="w-15px mr-2" font-scale="1">
            </b-icon>
            {{ $t('messages.createMessage') }}
          </h-button>
        </router-link>
      </div>
    </b-row>
    <b-row class="d-lg-none">
      <b-col>
        <router-link :to="{ name: '1' }">
          <h-button blue id="RecordButton" class="w-100">
            <b-icon icon="plus-circle-fill" class="w-15px mr-2" font-scale="1">
            </b-icon>
            {{ $t('messages.createMessage') }}
          </h-button>
        </router-link>
      </b-col>
    </b-row>
  </h-card>
</template>

<script>
export default {
  data() {
    return {
      selectedTags: [],
      collapsedFilters: true,
      search: '',
      selectedStatus: {
        label: this.$t('messages.broadcastingAndComing'),
        value: 5,
      },
      statusOptions: [
        { label: this.$t('messages.broadcasting'), value: 3 },
        { label: this.$t('messages.broadcastingAndComing'), value: 5 },
        { label: this.$t('messages.coming'), value: 1 },
      ],
    }
  },
  methods: {
    searchEvent() {
      this.$emit('search', this.search)
    },
    filter() {
      this.$emit('filter', this.selectedStatus)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters-row {
  padding-right: 15px;
  .row {
    padding-right: 0;
  }
}

@media screen and (max-width: 991.5px) {
  .filters-row {
    padding-right: 0;
    .row {
      padding-right: initial;
    }
  }
  .btn-filter-container {
    width: 100%;
    display: flex;
    height: 44px;
    min-height: 0;
    .filter-btn {
      width: 100%;
      display: block;
      margin: 0;
      box-sizing: border-box;
    }
  }
}

.rotate {
  transition: 0.1s ease-out;
}

.out {
  transition: 0.1s ease-out;
  transform: rotate(180deg);
}
</style>
