<template>
  <div
    class="d-flex title-line align-items-center pr-3"
    v-if="songItem.MyStatus !== 3"
  >
    <div class="draggable d-flex align-items-center cursor-pointer">
      <img
        class="mx-auto"
        src="@/assets/img/drag-points.svg"
        alt="drag points"
      />
    </div>
    <img
      :src="songItem.albumCover"
      alt="title cover"
      width="40"
      height="40"
      @error="imageLoadError"
    />
    <div class="song-title mx-3 font-weight-semi-bold flex-grow">
      {{ songItem.title }} - {{ songItem.artists }}
    </div>
    <div class="song-specs d-flex align-items-center ml-auto">
      <div class="song-from mx-4 d-flex align-items-center">
        <div
          v-if="songItem.foundStatus === 'loading'"
          class="spinner-border text-secondary mr-2"
          role="status"
        ></div>

        <b-icon v-else icon="check2" class="mr-2"></b-icon>
        <span class="font-weight-semi-bold">{{
          songItem.foundStatus === 'loading'
            ? 'Récupération du titre...'
            : songItem.foundStatus
        }}</span>
      </div>
    </div>
    <div @click="removeTrackItem(songItem.idFile)" class="ml-4 mr-2 trash">
      <b-icon icon="trash"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    songItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    imageLoadError() {
      const img = require(`@/assets/svg/default-cover.svg`)
      event.target.src = img
    },
    removeTrackItem(id) {
      this.$store.commit('musique/REMOVE_TRACK_ITEM_CURRENT_SKELETON', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
}

.title-line {
  height: 40px;
  margin-bottom: 7px;
  cursor: pointer;
  &:hover {
    background: #f1f5f7;
  }
  .draggable {
    border-radius: 2px;
    background: #f7f9fa;
    width: 17px;
    height: 40px;
  }
  img {
    margin-left: 9px;
    border-radius: 2px;
  }
  .song-title {
    color: $h-main-text;
    font-size: 1rem;
  }
  .song-from {
    color: '#6B6B6B';
  }
  .song-specs {
    white-space: nowrap;
  }
  .trash {
    color: #f11641;
    cursor: pointer;
  }
}
</style>
