<template>
  <div class="py-2 period">
    <p v-if="timeslots.length === 0" class="mb-0">
      {{ $t('messages.timeSlotsExplain') }}
    </p>
    <transition-group name="list">
      <b-row
        v-for="(timeslot, index) in timeslots"
        :key="index"
        class="mt-2 d-flex align-items-center"
      >
        <b-col cols="4" class="d-flex align-items-center pr-0">
          <b-icon icon="clock-fill" class="mr-2"></b-icon>
          <span class="mr-2 label"
            >{{ $t('messages.timeSlotItem') }} {{ index + 1 }}</span
          >
        </b-col>
        <b-col cols="7" class="pr-0 d-flex align-items-center">
          <h-input
            v-model="timeslots[index].start"
            :valueProp="timeslots[index].start"
            size="sm"
            type="time"
            @input="updateTimeSlots"
          ></h-input>
          <span class="mx-3">{{ $t('messages.to') }}</span>
          <h-input
            v-model="timeslots[index].end"
            :valueProp="timeslots[index].end"
            size="sm"
            type="time"
            @input="updateTimeSlots"
          ></h-input>
        </b-col>
        <b-col cols="1" class="cursor-pointer" @click="removeTimeSlot(index)">
          <b-icon scale="0.*" icon="trash-fill" class="text-red"></b-icon>
        </b-col>
      </b-row>
    </transition-group>

    <h-button @click="newTimeSlot" outline grey small class="mb-2 mt-4">
      <b-icon icon="plus" class="mr-2"></b-icon>{{ $t('messages.addTimeslot') }}
    </h-button>
  </div>
</template>

<script>
export default {
  props: {
    currentTimeSlots: {
      type: Array,
    },
    scheduleId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      timeslots: [],
    }
  },
  mounted() {
    this.timeslots = this.currentTimeSlots
  },
  methods: {
    newTimeSlot() {
      const obj = [{ start: null, end: null }]
      this.timeslots.push(obj)
    },
    removeTimeSlot(index) {
      this.timeslots.splice(index, 1)
      this.updateTimeSlots()
    },
    updateTimeSlots() {
      this.$emit('changeTimeslots', this.timeslots)
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 15px;
}
.period,
.range-input {
  color: #646e84;
}
.text-red {
  color: #f65e7c;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
